export function initCheckCheckBoxes () {
    let lowerCheckboxes = document.querySelectorAll('.special-checkboxes .checkboxes-lower input[type="checkbox"]')
    let upperCheckboxes = document.querySelectorAll('.special-checkboxes .checkbox-upper input[type="checkbox"]')

    if (lowerCheckboxes.length <= 0 || upperCheckboxes.length <= 0) {
        return
    }

    upperCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (e) => {
            lowerCheckboxes.forEach((lowerCheckbox) => lowerCheckbox.checked = checkbox.checked)
        })
    })

    lowerCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', (e) => {
            let allChecked = true
            let oneChecked = false
            lowerCheckboxes.forEach((lowerCheckbox) => {
                allChecked = lowerCheckbox.checked ? allChecked : false
                oneChecked = lowerCheckbox.checked || oneChecked
            })
            upperCheckboxes.forEach((upperCheckbox) => upperCheckbox.checked = allChecked)

            if (oneChecked) {
                [...lowerCheckboxes, ...upperCheckboxes].filter((checkbox) => !checkbox.checked).forEach((checkbox) => {
                    checkbox.required = false
                })
            } else {
                [...lowerCheckboxes, ...upperCheckboxes].forEach((checkbox) => {
                    checkbox.required = true
                })
            }
        })
    })
}
